// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-artist-layout-js": () => import("./../src/components/artistLayout.js" /* webpackChunkName: "component---src-components-artist-layout-js" */),
  "component---src-components-interview-page-js": () => import("./../src/components/interviewPage.js" /* webpackChunkName: "component---src-components-interview-page-js" */),
  "component---src-pages-artists-js": () => import("./../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

